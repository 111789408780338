export const photos = [
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Jessica+%26+Christian/FM3A2090.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Jessica+%26+Christian/JML_3777.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Jessica+%26+Christian/JML_7356.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Jessica+%26+Christian/JML_7454.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Jessica+%26+Christian/JML_7455.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Jessica+%26+Christian/JML_7458.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Jessica+%26+Christian/JML_7558.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Jessica+%26+Christian/JML_7583.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Jessica+%26+Christian/JML_7622.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Jessica+%26+Christian/JML_7882.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Jessica+%26+Christian/A73I6190.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Jessica+%26+Christian/FM3A1002.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Jessica+%26+Christian/FM3A1019.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Jessica+%26+Christian/FM3A1051.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Jessica+%26+Christian/FM3A1075.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Jessica+%26+Christian/FM3A1140.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Jessica+%26+Christian/FM3A1147.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Jessica+%26+Christian/FM3A1196.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Jessica+%26+Christian/FM3A1544.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Jessica+%26+Christian/FM3A1589.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Jessica+%26+Christian/FM3A1609.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Jessica+%26+Christian/FM3A1916.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Jessica+%26+Christian/FM3A1920.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Jessica+%26+Christian/FM3A1922.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Jessica+%26+Christian/FM3A1923.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Jessica+%26+Christian/FM3A1927.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Jessica+%26+Christian/FM3A1932.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Jessica+%26+Christian/FM3A1934.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Jessica+%26+Christian/FM3A1936.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Jessica+%26+Christian/FM3A1948.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Jessica+%26+Christian/FM3A1990.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Jessica+%26+Christian/FM3A1992.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Jessica+%26+Christian/FM3A1995.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Jessica+%26+Christian/FM3A1996.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Jessica+%26+Christian/FM3A2000.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Jessica+%26+Christian/FM3A2002.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Jessica+%26+Christian/FM3A2027.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Jessica+%26+Christian/FM3A2078.jpg",
    width: 3,
    height: 4,
  },
]
