import React from "react"
import Header from "../../components/header/header"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ImageGallery from "../../components/gallery/gallery-component"
import { photos } from "../../components/photos/j-s-photos"
import "../../../styles/global-styles.scss"

const JessicaAndChristian = () => (
  <Layout>
    <Header />
    <SEO
      title="Jessica & Christian"
      description="YESInspired Events creates minimalist wedding design that will inspire you, excite your guests, and leave a lasting impression on your big day. Bringing attention to the little details, Transforming the wedding industry with classy but affordable packages. "
    />
    <h1 className="gallery-photos-heading">Jessica & Christian</h1>
    <p className="gallery-photos-text-body">
      Jessica and Christian had their beautiful wedding ceremony at a Catholic
      Church in North York, Ontario and a minimalist modern reception at Hotel X
      located in Toronto, Ontario.
    </p>
    <ImageGallery photos={photos} />
  </Layout>
)

export default JessicaAndChristian
